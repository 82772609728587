<template>
<h1 class="text-center">Steps</h1>
</template>

<script>
export default {
  name: "Steps"
}
</script>

<style scoped>

</style>